var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-uploads"},[_c('h2',[_vm._v("Media Library")]),(_vm.checkedImages.length > 0)?_c('div',{staticClass:"d-flex menu-checked-items"},[_c('div',{staticClass:"toolbar dark"},[_c('div',{staticClass:"item delete"},[_c('el-tooltip',{attrs:{"effect":"dark","content":"Delete"}},[_c('IcoBin',{on:{"click":function($event){return _vm.removeImages(_vm.checkedImages)}}})],1)],1)])]):_vm._e(),_c('uploader',{attrs:{"supportedFileTypes":_vm.supportedFileTypes},on:{"upload":function (file) {
        this$1.$_dashboardMixin_uploadImageToS3(file, _vm.supportedFileTypes).then(
          function () {
            this$1.getAllUploads();
          }
        );
      }}}),_c('loading',{attrs:{"active":_vm.$wait.is('loadingImages') ||
        _vm.$wait.is('uploadingImage') ||
        _vm.$wait.is('deletingImage'),"is-full-page":false}}),(_vm.uploads.length === 0)?_c('div',{staticClass:"mt4 text-center"},[_c('img',{staticClass:"img-empty-uploads",attrs:{"src":(_vm.publicPath + "assets/uploads.svg"),"alt":""}})]):_c('el-checkbox-group',{model:{value:(_vm.checkedImages),callback:function ($$v) {_vm.checkedImages=$$v},expression:"checkedImages"}},[_c('masonry',{staticClass:"masonry-layout",attrs:{"cols":4,"gutter":30}},_vm._l((_vm.uploads),function(image,index){return _c('div',{key:index,staticClass:"item",attrs:{"draggable":""}},[_c('div',{staticClass:"item-top"},[_c('img',{staticClass:"gallery-image",attrs:{"src":image.image_url,"alt":""}}),_c('div',{class:{
              hover: true,
              selected: _vm.checkedImages.indexOf(image._id) > -1,
            }},[_c('el-checkbox',{staticClass:"checkbox-item",attrs:{"size":"medium","border":true,"value":image._id,"label":image._id}})],1)])])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }