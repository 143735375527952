<template>
  <div class="page-uploads">
    <h2>Media Library</h2>

    <div v-if="checkedImages.length > 0" class="d-flex menu-checked-items">
      <div class="toolbar dark">
        <div class="item delete">
          <el-tooltip effect="dark" content="Delete">
            <IcoBin @click="removeImages(checkedImages)"></IcoBin>
          </el-tooltip>
        </div>
      </div>
    </div>

    <uploader
      @upload="
        (file) => {
          this.$_dashboardMixin_uploadImageToS3(file, supportedFileTypes).then(
            () => {
              this.getAllUploads();
            }
          );
        }
      "
      :supportedFileTypes="supportedFileTypes"
    ></uploader>

    <loading
      :active="
        $wait.is('loadingImages') ||
          $wait.is('uploadingImage') ||
          $wait.is('deletingImage')
      "
      :is-full-page="false"
    ></loading>

    <div v-if="uploads.length === 0" class="mt4 text-center">
      <img
        class="img-empty-uploads"
        :src="`${publicPath}assets/uploads.svg`"
        alt=""
      />
    </div>

    <el-checkbox-group v-else v-model="checkedImages">
      <masonry class="masonry-layout" :cols="4" :gutter="30">
        <div
          class="item"
          draggable
          v-for="(image, index) in uploads"
          :key="index"
        >
          <div class="item-top">
            <img class="gallery-image" :src="image.image_url" alt="" />
            <div
              :class="{
                hover: true,
                selected: checkedImages.indexOf(image._id) > -1,
              }"
            >
              <el-checkbox
                class="checkbox-item"
                size="medium"
                :border="true"
                :value="image._id"
                :label="image._id"
              ></el-checkbox>
            </div>
          </div>
        </div>
      </masonry>
    </el-checkbox-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "@/config";
import { DASHBOARD_MIXIN } from "@/components/common/mixins/dashboard.mixins";
import IcoBin from "@/components/common/ico/delete-24px.svg";
import uploader from "@/components/common/uploader/uploader.component";

import { loadAWSsdk } from "@/libs/aws-manager";

export default {
  mixins: [DASHBOARD_MIXIN],
  components: {
    IcoBin,
    uploader,
  },
  data() {
    return {
      uploads: [],
      checkedImages: [],
      supportedFileTypes: ["png", "jpg", "jpeg", "gif", "bmp", "svg"],
    };
  },
  computed: {
    ...mapGetters("brandModule", ["userUploadedImages"]),
    ...mapActions("brandModule", ["getUserUploadedImages", "deleteImages"]),
  },
  watch: {
    userUploadedImages: function(newProps) {
      this.uploads = newProps;
    },
  },
  mounted: function() {
    loadAWSsdk();

    this.getAllUploads();
  },
  methods: {
    getAllUploads() {
      this.$wait.start("loadingImages");

      this.$store
        .dispatch("brandModule/getUserUploadedImages")
        .then((response) => {
          this.$wait.end("loadingImages");

          this.uploads = response;
        })
        .catch((e) => {
          this.$wait.end("loadingImages");

          console.error(e);
        });
    },

    removeImages(imageIds) {
      this.$wait.start("deletingImage");

      const images = this.uploads.filter((img) => {
        return imageIds.indexOf(img._id) > -1;
      });

      this.$store
        .dispatch("brandModule/deleteImages", images)
        .then((responses) => {
          this.$wait.end("deletingImage");
          this.checkedImages = [];

          this.getAllUploads();
        })
        .catch((e) => {
          this.$wait.end("deletingImage");
          console.warn(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "uploads.component.scss";
</style>
